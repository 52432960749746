<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-content>
        <div class="table-wrapper mt-4">
          <page-title :pageTitle="$t('account.profile')" />
          <va-inner-loading
            :loading="getUserLoading"
            color="primary"
            class="va-loading"
            :size="60"
            v-if="getUserLoading"
          />
          <user-form
            v-if="!getUserLoading"
            :loading="updateUserLoading"
            :initialData="user"
            :submitAction="submitAction"
          />
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import { GET_ME, UPDATE_USER } from '@mdb/core/src';
import UserForm from './UserForm.vue';
import PageTitle from '@/components/text/PageTitle';
import { useGetEntity } from '@/components/helpers/useGetEntity';
import { useFormMutation } from '@/components/helpers/useFormMutation';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import useUpdateUser from './useUpdateUser';
import AuthenticationService from '@/components/helpers/AuthenticationService';

export default {
  components: { UserForm, PageTitle },
  setup(props) {
    const route = useRoute();

    const { item: user, loading: getUserLoading, error } = useGetEntity({}, GET_ME);
    const { result, updateUserLoading, submitForm } = useUpdateUser(AuthenticationService.currentUser().id);

    return {
      error,
      user,
      getUserLoading,
      updateUserLoading,
      submitAction: submitForm
    };
  }
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>

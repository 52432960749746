import { createI18n } from 'vue-i18n';
import fr from './fr.json';

export default createI18n({
  datetimeFormats: {
    fr: {
      short: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
    }
  },
  locale: 'fr',
  messages: { fr }
});

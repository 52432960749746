import { useQuery, useResult } from '@vue/apollo-composable';
import { useToast } from 'vue-toastification';
import { isEmpty } from 'lodash';
const toast = useToast();
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
/**
 * Return an list of element(s)
 * @param {*} currentPage | null page requested
 * @param {*} perPage | null  number of element per page
 * @param {*} filter | null filter object
 * @param {*} query graphQL query
 * @returns
 */
export function useGetEntity(data, query, options = {}) {
  const { t } = useI18n({ useScope: 'global' });

  const router = useRouter();

  const { result, loading, error, onError, refetch } = useQuery(
    query,
    () => data,
    isEmpty(options) ? {} : options.value
  );
  const item = useResult(result, [], data => data.result);

  onError(({ graphQLErrors, networkError }) => {
    let error = '';
    if (graphQLErrors) {
      if (graphQLErrors[0].message === 'EnitityNotFound') {
        router.push({ path: '/not-found' });
        return;
      }
      error += graphQLErrors.map(({ message, locations, path }) => `[GraphQL error]: Message: ${message}`);
    }
    if (networkError) {
      // Add something like this to set the error message to the one from the server response
      error += networkError.result.errors.map(error => `[Network error]: ${error.message}`);
    }
    toast(t('form.error.generic_message.query') + '\n' + error, {
      timeout: false,
      type: 'error',
      id: 'error-toast'
    });
  });

  return {
    loading: loading,
    error: error,
    item: item,
    refetch
  };
}

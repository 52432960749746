import i18n from '@//i18n';

const { t } = i18n.global;
import { GET_USER, UPDATE_USER } from '@mdb/core/src';
import { useGetEntity } from '@/components/helpers/useGetEntity';
import { useFormMutation } from '@/components/helpers/useFormMutation';
import AuthenticationService from '@/components/helpers/AuthenticationService';
import ToastService from '@/components/helpers/ToastService';
import { useRoute, useRouter } from 'vue-router';
import { ref, watch } from 'vue';
const useUpdateUser = (id: string) => {
  const { loading: updateUserLoading, mutate, onDone } = useFormMutation(UPDATE_USER);
  const router = useRouter();
  const userId = ref(id);
  const redirect = ref(false);
  onDone(() => {
    if (redirect.value) {
      AuthenticationService.logout();
      ToastService.addToLocalStorage([
        { id: 'sucess-password-update', success: true, message: t('account.password_change_success') }
      ]);
      router.push({ name: 'login' });
    }
  });

  const submitForm = async (data: any) => {
    if (data.newPassword) {
      redirect.value = true;
    }
    await mutate({ data: data, id: userId.value });
  };

  return { updateUserLoading, submitForm, onDone };
};

export default useUpdateUser;
